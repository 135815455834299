import React, {useMemo} from 'react';
import styled from "styled-components";
import GridContainer from "@/components/common/GridContainer";
import Button from "@/components/common/Buttons/Button";
import {BREAKPOINTS} from "@/styles/themeSettings";
import {getCssSizeValue, getNewsUrl, getPaddingsClassFromData} from "@/utils/utils";
import {useBranch} from "@/hooks/useBranch";
import ListCards from "@/components/common/Cards/List";
import Container from "@/components/common/Container";
import Reveal from "@/animations/Reveal";
import useGlobals from "@/hooks/useGlobals";
import {GLOBAL_STATE, PAGES} from "@/constants";

const NewsThumbnailContainer = styled(Container)`
	padding-bottom: 0 !important;
`

const StyledHeaderAndButtonWrapper = styled(GridContainer)`
	align-items: center;
    margin-bottom: ${getCssSizeValue(28, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        margin-bottom: ${getCssSizeValue(64, BREAKPOINTS.TABLET)};
	}
	
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		 margin-bottom: ${getCssSizeValue(64, BREAKPOINTS.DESKTOP)} // 64px - 40px margin
	}
`

const StyledButtonWrapper =styled.div`
	display: flex;
	justify-content: end;
	align-items: end;
`

function useGetCurrentStateData() {
	const allStates = useGlobals(state => state.states)
	const {selectedOption} = useBranch();

	return allStates.find(state => (state?.handle || '') === selectedOption)
}

const NewsThumbnail = ({ data }) => {
	const header = data?.header || '';
	const buttonText = data?.buttonText || '';
	const buttonLink = data?.buttonLink?.slug || '';
	const paddings = getPaddingsClassFromData(data)
	const state = useGetCurrentStateData()
	const shouldDisplayGlobalNews = state?.shouldDisplayGlobalNews || false;
	const { selectedOption } = useBranch();
	const stateToFilterBy = shouldDisplayGlobalNews ? GLOBAL_STATE : selectedOption;
	const filteredNews = useMemo(() => {
		const allNewsAssigned = data?.news || [];
		return allNewsAssigned.filter(item => item?.state?.handle?.toLowerCase() === stateToFilterBy);
	}, [selectedOption, data]);

	const listNews = useMemo(() => {
		return filteredNews.map((item) => ({ ...item, url: getNewsUrl(item) }))
	},[filteredNews])
	return (
		<>
			<NewsThumbnailContainer
				className={paddings}
			>
				<StyledHeaderAndButtonWrapper className="no-margin">
					<Reveal onInView className="col-mobile-8 col-tablet-5 col-desktop-3">
						<p className="typo-borna typo-borna-10">
							{header}
						</p>
					</Reveal>
					<StyledButtonWrapper className="col-mobile-3 col-tablet-2 col-desktop-2" style={{gridColumnStart: 15, gridColumnEnd: 17 }}>
						<Button as="link" href={`${PAGES.POLISH_RED_CROSS}/${buttonLink}`} variant="tertiary">
							{buttonText}
						</Button>
					</StyledButtonWrapper>
				</StyledHeaderAndButtonWrapper>
				{/*<ListCards data={listNews} animation/>*/}
			</NewsThumbnailContainer>
			<ListCards data={listNews} animation/>
		</>
	);
};

export default NewsThumbnail;
